<template>
  <MCarouselItems
      :items="itemsList"
      :slider-settings="sliderSettings"
      class="m-brands-carousel"
      order-key="brand_position"
      arrow-color="white"
  >
    <template v-slot="{item}">
      <div
        class="m-brands-carousel__item"
        data-transaction-name="Brands - Carousel - Go To Brand"
        @click="handleBrandClick(item)"
      >
        <MRenderImage
          :brand-id="item.pim_brand_id"
          :name="item.name"
        />
      </div>
    </template>
  </MCarouselItems>
</template>
<script>
import MCarouselItems from 'theme/components/molecules/m-carousel-items'
import MRenderImage from 'theme/components/molecules/m-render-image'
import { mapState } from 'vuex';
import {
  BREAKPOINT_SM,
  BREAKPOINT_MD,
  BREAKPOINT_XL
} from 'theme/helpers/breakpoints';

export default {
  name: 'MBrandsCarouselHomePage',
  components: {
    MCarouselItems,
    MRenderImage
  },
  data  () {
    const breakpoints = {
      XL: {
        width: BREAKPOINT_XL - 1,
        items: 6
      },
      MD: {
        width: BREAKPOINT_MD - 1,
        items: 4
      },
      SM: {
        width: BREAKPOINT_SM,
        items: 3
      }
    }

    return {
      sliderSettings: {
        type: 'slider',
        animationDuration: 400,
        animationTimingFunc: 'ease-in-out',
        perView: 6,
        gap: 10,
        dragThreshold: false,
        peek: {
          before: 0,
          after: 0
        },
        rewind: false,
        breakpoints: {
          [breakpoints.XL.width]: {
            perView: breakpoints.XL.items
          },
          [breakpoints.MD.width]: {
            perView: breakpoints.MD.items,
            gap: 11
          },
          [breakpoints.SM.width]: {
            perView: breakpoints.SM.items,
            peek: {
              before: 0,
              after: 160
            }
          },
          [breakpoints.SM.width - 1]: {
            perView: 2,
            peek: {
              before: 0,
              after: 0
            },
            swipeThreshold: false,
            dragThreshold: false
          }
        }
      }
    }
  },
  props: {
    arrowColor: {
      type: String,
      default: 'gray'
    },
    brandsList: {
      type: Array,
      default: () => []
    },
    brandsType: {
      type: String,
      default: () => 'homePage'
    }
  },
  computed: {
    ...mapState({
      brandListFromState: state => state.brands.brandsList
    }),
    itemsList () {
      return this.brandsType === 'promotion' ? this.brandsList : this.brandListFromState
    }
  },
  methods: {
    handleBrandClick (item) {
      const url = item.url_path || item.url_key;
      this.$router.push(url);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
@import "~theme/css/breakpoints";
@import "~theme/css/px2rem";

.m-brands-carousel {
  &__item {
    display: flex;
    justify-content: center;
    cursor: pointer;
    overflow: hidden;
    height: px2rem(80);
    padding: 0 var(--spacer-10);

    ::v-deep {
      img {
        object-fit: contain;
      }
    }
  }

  &__image {
    display: flex;
    justify-content: center;

    @media (min-width: $tablet-min) {
      max-width: 100%;
      width: 100%;
    }
  }

  ::v-deep {
    img {
      --image-width: auto;
      margin: auto;
      height: auto;
    }

    .sf-carousel__controls {
      .sf-arrow--white {
        background-color: var(--light-gray);

        &:active {
          background-color: var(--orange-pressed);
        }
      }
      .sf-arrow--active {
        background-color: var(--gray-30);
      }
    }

    .glide__slide {
      display: flex;
      justify-content: center;
    }
    .glide__track {
      overflow: auto;

      @media (min-width: $desktop-min) {
        overflow: unset;
      }
    }
  }
}
</style>
