<template>
  <component :is="'script'" v-html="jsonld" type="application/ld+json" />
</template>

<script>
import config from 'config';
import { mapGetters } from 'vuex';

export default {
  name: 'JsonLdHome',
  computed: {
    ...mapGetters({ baseUrl: 'siteData/baseUrl' }),
    content () {
      return {
        '@context': 'https://schema.org/',
        '@type': 'WebSite',
        'url': this.baseUrl + '/',
        'potentialAction': {
          '@type': 'SearchAction',
          'target': `${this.baseUrl}/search?q={search_term_string}`,
          'query-input': 'required name=search_term_string'
        },
        'provider': {
          '@type': 'Organization',
          'address': config.seo?.address || '',
          'email': config.seo?.email || '',
          'name': config.seo?.name || '',
          'telephone': config.seo?.contactPhone || ''
        }
      };
    },
    jsonld () {
      return JSON.stringify(this.content, null, 2);
    }
  }
}
</script>
