<template>
  <div class="m-products-add-to-cart">
    <SfButton
      class="sf-button--full-width"
      @click="addAllProductsToCart"
    >
      {{ title }}
    </SfButton>
    <ALoadingSpinner :is-absolute-position="true" v-show="isShowSpinner" />
  </div>
</template>

<script>
import { SfButton } from '@storefront-ui/vue'
import { Wishlist } from 'theme/store/wishlist/components/Wishlist';
import { mapGetters } from 'vuex';
import { notifications } from '@vue-storefront/core/modules/cart/helpers';
import ALoadingSpinner from 'theme/components/atoms/a-loading-spinner'
import i18n from '@vue-storefront/i18n';

export default {
  name: 'MProductsAddToCart',
  components: {
    SfButton,
    ALoadingSpinner
  },
  mixins: [Wishlist],
  props: {
    products: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      default: () => i18n.t('Add all products to cart')
    }
  },
  data () {
    return {
      isShowSpinner: false
    }
  },
  computed: {
    ...mapGetters({
      productsInCart: 'cart/getCartItems'
    })
  },
  methods: {
    async addAllProductsToCart () {
      this.isShowSpinner = true

      const productsLength = this.products.length
      const productsToAdd = []

      for (let i = 0; i < productsLength; i++) {
        let product = this.products[i]
        let indexInCart = this.productsInCart.findIndex(p => p.id === product.id)

        if (indexInCart === -1) {
          productsToAdd.push(Object.assign({}, product))
        }
      }

      try {
        const diffLog = await this.$store.dispatch('cart/addItems', {
          productsToAdd
        })
        diffLog.clientNotifications.forEach(notificationData => {
          this.$store.dispatch(
            'notification/spawnNotification',
            notificationData,
            { root: true }
          );
        });
      } catch (message) {
        await this.$store.dispatch(
          'notification/spawnNotification',
          notifications.createNotification({ type: 'danger', message }),
          { root: true }
        );
      }

      this.isShowSpinner = false
    }
  }
};
</script>
