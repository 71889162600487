<template>
  <div class="carousel-items">
    <div
        v-if="!isInitiatedGlide"
        class="carousel-items__scrollable">
      <div class="carousel-items__scrollable-line">
        <div
            v-for="(item, key) in carouselItems"
            :key="key"
            class="sf-carousel-item glide__slide"
            :style="orderKey ? `order: ${item[orderKey] ? item[orderKey] : 0}` : ''"
        >
          <slot :item="item" />
        </div>
      </div>
    </div>
    <SfOCarousel
      v-else
      class="slider-carousel"
      :class="carouselClass"
      :settings="sliderSettings"
      ref="slider"
      v-bind="commonProps"
    >
      <template #prev="{go}">
        <SfButton
            v-show="(sliderPerPage > 1)"
            class="sf-arrow sf-arrow--rounded sf-arrow-prev"
            :class="prevButtonClass"
            aria-label="previous"
            @click="goPrev(); go('prev')"
            :disabled="sliderData.disablePrev"
        />
      </template>
      <SfCarouselItem
          v-for="(item, key) in carouselItems"
          :key="key"
          :style="orderKey ? `order: ${item[orderKey] ? item[orderKey] : 0}` : ''"
      >
        <slot :item="item" />
      </SfCarouselItem>
      <template #next="{go}">
        <SfButton
            v-show="(sliderPerPage > 1)"
            class="sf-arrow sf-arrow--rounded sf-arrow-next"
            :class="nextButtonClass"
            aria-label="next"
            @click="goNext(); go('next')"
            :disabled="sliderData.disableNext"
        />
      </template>
    </SfOCarousel>
  </div>
</template>
<script>
import SfOCarousel from 'theme/components/storefront-override/SfOCarousel'
import { SfButton } from '@storefront-ui/vue'
import { BREAKPOINT_SM, BREAKPOINT_MD, BREAKPOINT_LG, BREAKPOINT_XSM, BREAKPOINT_XL } from 'theme/helpers/breakpoints'
import DeviceType from 'theme/mixins/DeviceType'

export default {
  name: 'MCarouselItems',
  props: {
    items: {
      type: Array,
      default: () => {
        return []
      }
    },
    sliderSettings: {
      type: Object,
      default: () => {
        return {
          type: 'slider',
          animationDuration: 400,
          animationTimingFunc: 'ease-in-out',
          perView: 6,
          gap: 10,
          dragThreshold: false,
          breakpoints: {
            [BREAKPOINT_LG - 1]: {
              perView: 5
            },
            [BREAKPOINT_MD - 1]: {
              perView: 4
            },
            [BREAKPOINT_SM - 1]: {
              perView: 2.3,
              swipeThreshold: false,
              dragThreshold: false
            }
          }
        };
      }
    },
    showQtyPermanently: {
      type: Boolean,
      default: false
    },
    arrowColor: {
      type: String,
      default: 'gray'
    },
    carouselClass: {
      type: String,
      default: ''
    },
    orderKey: {
      type: [String, Boolean],
      default: false
    },
    commonProps: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  components: {
    SfOCarousel,
    SfButton
  },
  mixins: [
    DeviceType
  ],
  data () {
    return {
      isInitiatedGlide: false,
      sliderData: {
        currentPage: 1,
        disablePrev: true,
        disableNext: false
      },
      carouselUpdateKey: 0
    }
  },
  computed: {
    styleForSize () {
      const breakpointXSM = this.sliderSettings?.breakpoints?.[BREAKPOINT_XSM - 1]?.perView || 2;
      const breakpointSM = this.sliderSettings?.breakpoints?.[BREAKPOINT_SM - 1]?.perView || 3
      const breakpointMD = this.sliderSettings?.breakpoints?.[BREAKPOINT_MD - 1]?.perView || breakpointLG
      const breakpointLG = this.sliderSettings?.breakpoints?.[BREAKPOINT_LG - 1]?.perView || breakpointXL
      const breakpointXL = this.sliderSettings?.breakpoints?.[BREAKPOINT_XL - 1]?.perView || this.sliderSettings?.perView || 4

      return [
        `--xsm-break-val: ${breakpointXSM}`,
        `--sm-break-val: ${breakpointSM}`,
        `--md-break-val: ${breakpointMD}`,
        `--lg-break-val: ${breakpointLG}`,
        `--xl-break-val: ${breakpointXL}`
      ].join(';')
    },
    carouselItems () {
      return this.items
    },
    sliderPerPage () {
      return Math.ceil(this.carouselItems.length / this.sliderSettings.perView)
    },
    prevButtonClass () {
      return [`sf-arrow--${this.arrowColor}`, { 'sf-arrow--active': !this.sliderData.disablePrev }]
    },
    nextButtonClass () {
      return [`sf-arrow--${this.arrowColor}`, { 'sf-arrow--active': !this.sliderData.disableNext }]
    }
  },
  watch: {
    isDesktop: {
      immediate: true,
      handler: function (val, oldVal) {
        if (val === oldVal) return

        this.$nextTick(() => {
          this.isInitiatedGlide = val
        })
      }
    }
  },
  methods: {
    goNext () {
      this.sliderData.currentPage = (this.sliderData.currentPage + 1)
      this.sliderData.disableNext = (this.sliderPerPage === this.sliderData.currentPage)
      this.sliderData.disablePrev = false
    },
    goPrev () {
      this.sliderData.currentPage = (this.sliderData.currentPage - 1)
      this.sliderData.disablePrev = (this.sliderData.currentPage === 1)
      this.sliderData.disableNext = false
    }
  },
  mounted () {
    if (this.sliderPerPage === 1) {
      this.sliderData.disableNext = true
      this.sliderData.disablePrev = true
    }
  }
}
</script>
<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
@import 'theme/css/breakpoints';
@import "~theme/css/px2rem";

.carousel-items {
  width: 100%;

  &__scrollable {
    --product-scrollable-gap: var(--spacer-10);
    overflow: auto hidden;
    display: flex;

    &::-webkit-scrollbar {
      display: none;
    }

    @-moz-document url-prefix() {
      scrollbar-width: none;
    }

    &-line {
      display: flex;
      flex-wrap: nowrap;
      width: 100%;
      gap: var(--spacer-10);
      padding-left: var(--spacer-10);
      padding-right: var(--spacer-10);
      box-sizing: content-box;

      > * {
        min-width: calc(40% - 10px);
        max-width: calc(40% - 10px);

        @media (min-width: 489px){
          min-width: calc(40% - 10px);
          max-width: calc(40% - 10px);
        }

        @media (min-width: $tablet-min){
          min-width: calc(100%/var(--md-break-val, 4) - 10px);
          max-width: calc(100%/var(--md-break-val, 4) - 10px);
        }

        @media (min-width: $desktop-min){
          min-width: calc(100%/var(--lg-break-val, 4) - 10px);
          max-width: calc(100%/var(--lg-break-val, 4) - 10px);
        }

        @media (min-width: $desktop-l-min){
          min-width: calc(100%/var(--xl-break-val, 4) - 10px);
          max-width: calc(100%/var(--xl-break-val, 4) - 10px);
        }

        &:last-child {
          margin-right: var(--spacer-10);
        }
      }
    }
  }

  ::v-deep {

    .sf-carousel__controls {
      @media (min-width: $tablet-min) {
        left: auto;
        right: var(--spacer-10);
        top: -2.2rem;
        position: absolute;
        width: auto;
      }
    }

    .sf-items-item {
      height: auto;
      width: px2rem(238);

      @include for-desktop {
        width: px2rem(290);
      }

      .sf-price__special {
        @include for-mobile {
          margin-bottom: 2px;
        }
      }
    }

    .glide__slide {
      cursor: pointer;
    }

    .glide__track,
    .glide__slides,
    .glide__slide {
      @include for-mobile {
        &::-webkit-scrollbar {
          display: none;
        }

        @-moz-document url-prefix() {
          scrollbar-width: none;
        }
      }
    }

    .glide__slides,
    .glide__slide {
      @include for-mobile {
        overflow: auto hidden;
      }
    }

    /* Skeleton part */
    .glide:not(.glide--slider) .glide__slides {
      @include only-mobile {
        padding-right: var(--spacer-10);
        width: 135%; // to show 2.3 items
      }
    }

    .glide:not(.glide--slider) .glide__slide {
      &:first-child {
        margin-right: var(--spacer-5);
      }

      @include only-mobile {
        &:nth-child(2) {
          margin-inline: var(--spacer-5);
        }

        &:nth-child(3) {
          margin-left: var(--spacer-5);
        }

        &:not(:nth-child(-n+3)) {
          display: none;
        }
      }

      @include for-tablet {
        &:nth-child(2), &:nth-child(3) {
          margin-inline: var(--spacer-5);
        }

        &:nth-child(4) {
          margin-left: var(--spacer-5);
        }

        &:not(:nth-child(-n+4)) {
          display: none;
        }
      }

      @media (min-width: $desktop-min) and (max-width: $desktop-l-min) {
        &:nth-child(2), &:nth-child(3), &:nth-child(4) {
          margin-inline: var(--spacer-5);
        }

        &:nth-child(5) {
          margin-left: var(--spacer-5);
        }

        &:not(:nth-child(-n+5)) {
          display: none;
        }
      }

      @media (min-width: $desktop-l-min) {
        &:nth-child(n+2):nth-child(-n+5) { // nth from 2 to 5
          margin-inline: var(--spacer-5);
        }

        &:nth-child(6) {
          margin-left: var(--spacer-5);
        }

        &:not(:nth-child(-n+6)) {
          display: none;
        }
      }
    }
  }
}
</style>
