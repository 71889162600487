var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"m-product-tags"},[(_vm.tags.length)?_c('div',{staticClass:"m-product-tags__list"},[(_vm.isAllTagsRequired)?_c('div',{staticClass:"m-product-tags__link",class:{
        'm-product-tags__link--active': _vm.activeTagIndex === -1,
        'm-product-tags__link--prev': _vm.activeTagIndex === 1
      },on:{"click":function($event){return _vm.setActiveCategory(-1)}}},[_c('span',[_vm._v(_vm._s(_vm.$t('All')))])]):_vm._e(),_vm._v(" "),_vm._l((_vm.tags),function(tag,i){return _c('div',{key:i,staticClass:"m-product-tags__link",class:{
        'm-product-tags__link--active': _vm.activeTagIndex === i,
        'm-product-tags__link--prev': _vm.activeTagIndex === i + 1
      },on:{"click":function($event){return _vm.setActiveCategory(i)}}},[(_vm.isItHaveACounter)?[_c('span',[_vm._v(_vm._s(tag.name)+" ("+_vm._s(tag.items.length)+")")])]:[_c('span',[_vm._v(_vm._s(tag.name))])]],2)})],2):_c('div',{staticClass:"m-product-tags__list"},[_c('SfLink',{staticClass:"m-product-tags__link m-product-tags__link--active",attrs:{"link":"#"}},[_c('span',[_vm._v("Tag")])]),_vm._v(" "),_c('SfLink',{staticClass:"m-product-tags__link",attrs:{"link":"#"}},[_c('span',[_vm._v("Tag")])]),_vm._v(" "),_c('SfLink',{staticClass:"m-product-tags__link",attrs:{"link":"#"}},[_c('span',[_vm._v("Tag")])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }