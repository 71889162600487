<template>
  <div class="m-product-tags">
    <div class="m-product-tags__list" v-if="tags.length">
      <div
        v-if="isAllTagsRequired"
        class="m-product-tags__link"
        :class="{
          'm-product-tags__link--active': activeTagIndex === -1,
          'm-product-tags__link--prev': activeTagIndex === 1
        }"
        @click="setActiveCategory(-1)"
      >
        <span>{{ $t('All') }}</span>
      </div>
      <div
        v-for="(tag, i) in tags"
        :key="i"
        class="m-product-tags__link"
        :class="{
          'm-product-tags__link--active': activeTagIndex === i,
          'm-product-tags__link--prev': activeTagIndex === i + 1
        }"
        @click="setActiveCategory(i)"
      >
        <template v-if="isItHaveACounter">
          <span>{{ tag.name }} ({{ tag.items.length }})</span>
        </template>
        <template v-else>
          <span>{{ tag.name }}</span>
        </template>
      </div>
    </div>
    <div class="m-product-tags__list" v-else>
      <SfLink class="m-product-tags__link m-product-tags__link--active" link="#">
        <span>Tag</span>
      </SfLink>
      <SfLink class="m-product-tags__link" link="#">
        <span>Tag</span>
      </SfLink>
      <SfLink class="m-product-tags__link" link="#">
        <span>Tag</span>
      </SfLink>
    </div>
  </div>
</template>
<script>
import {
  SfLink
} from '@storefront-ui/vue';
export default {
  name: 'MProductTags',
  components: {
    SfLink
  },
  props: {
    tags: {
      type: [Array, Object],
      default: () => []
    },
    isAllTagsRequired: {
      type: Boolean,
      default: false
    },
    isItHaveACounter: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      activeTagIndex: this.isAllTagsRequired ? -1 : 0
    };
  },
  methods: {
    setActiveCategory (index) {
      this.activeTagIndex = index;
      this.$emit('setActiveCategory', index)
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.m-product-tags {
  order: 3;
  margin: 0 calc(-1 * var(--spacer-10));
  overflow-y: hidden;
  width: auto;
  padding-bottom: var(--spacer-5);
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (min-width: $tablet-min) {
    margin-top: var(--spacer-15);
  }

  @media (min-width: $desktop-min) {
    order: unset;
    margin: 0 auto 0 var(--spacer-40);
    padding-bottom: 0;
  }

  @media (max-width: $tablet-max) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    margin-top: var(--spacer-10);
  }

  &__list {
    border-radius: 10px;
    display: inline-flex;
    overflow-x: auto;
    background: rgba(255, 255, 255, 0.4);
    padding: var(--spacer-2xs);
  }

  &__link{
    position: relative;
    padding: 0 var(--spacer-sm);
    border-radius: 7px;
    color: var(--orange);
    cursor: pointer;
    white-space: nowrap;
    height: 42px;
    display: flex;
    align-items: center;

    span {
      font-size: var(--font-base);
      line-height: var(--font-size-19);
    }

    &:not(:last-child):not(.m-product-tags__link--active) {
      &:after {
        content: '';
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(0, -50%);
        width: 1px;
        height: 1.75rem;
        background: var(--green-light);
      }
    }

    &.sf-link{
      color: var(--orange);
    }

    &--active{
      background: var(--white);
      color: var(--black);
      cursor: default;
      margin-left: -1px;

      &:first-child {
        margin-left: 0;
      }

      &.sf-link{
        color: var(--black);
      }
    }

    &:hover{
      cursor: pointer;
      color: var(--primary-black);
    }
  }
}
</style>
