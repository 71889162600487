<template>
  <SfHero
    class="hero"
    :slider-options="sliderOptions"
    v-if="preparedHeroes.length"
  >
    <SfOHeroItem
      v-for="(hero, i) in preparedHeroes"
      :key="i"
      :title="hero.title || ''"
      :title-show="+hero.show_title || 0"
      :subtitle="hero.subtitle || ''"
      :sub-title-show="+hero.show_subtitle || 0"
      :subtitle-color="hero.subtitle_color || ''"
      :button-show="+hero.button_show || 0"
      :image-mobile="hero.image.mobile"
      :image-desktop="hero.image.desktop"
      :background="hero.background_color || ''"
      class="sf-hero-item--position-bg-top-right"
      :is-mobile="isMobile"
    >
      <template #title>
        <div
          v-if="hero.title"
          class="sf-hero-item__title"
          :style="{ color: hero.title_color }"
        >
          {{ hero.title }}
        </div>
      </template>
      <template #call-to-action>
        <SfLink
          :link="hero.link"
          :target="hero.target"
          @click.native="onBannerClick(hero)"
        >
          <SfButton
            :style="{ color: hero.button_text_color, backgroundColor: hero.button_color }"
            class="sf-button--base sf-button--mq"
          >
            {{ hero.button_text }}
          </SfButton>
        </SfLink>
      </template>
      <template #big-link v-if="hero.link">
        <SfLink
          class="big-link"
          :link="hero.link"
          :target="hero.target"
          @click.native="onBannerClick(hero)"
        />
      </template>
    </SfOHeroItem>
    <template #prev="{go}">
      <SfButton
        class="sf-arrow sf-arrow--rounded sf-arrow--white sf-arrow-prev"
        aria-label="previous"
        data-transaction-name="Home Hero Prev"
        @click="go('prev')"
      />
    </template>
    <template #next="{go}">
      <SfButton
        class="sf-arrow sf-arrow--rounded sf-arrow--white sf-arrow-next"
        aria-label="next"
        data-transaction-name="Home Hero Next"
        @click="go('next')"
      />
    </template>
  </SfHero>
</template>
<script>
import { getThumbnailPath } from '@vue-storefront/core/helpers';
import SfOHeroItem from 'theme/components/storefront-override/SfOHeroItem'
import {
  SfHero,
  SfButton,
  SfLink
} from '@storefront-ui/vue';
import GoogleTagManager from 'theme/mixins/gtm'

const TOP_BANNER_POSITION_INDEX = '0';

export default {
  name: 'OHomeHero',
  mixins: [GoogleTagManager],
  data () {
    return {
      sliderOptions: {
        type: 'carousel',
        animationDuration: 1000,
        rewindDuration: 2000,
        animationTimingFunc: 'ease-out',
        gap: 10,
        perView: 1,
        hoverpause: false,
        breakpoints: {
          450: {
            peek: {
              before: 0,
              after: 0
            }
          },
          500: {
            peek: {
              before: 20,
              after: 20
            }
          },
          550: {
            peek: {
              before: 40,
              after: 40
            }
          },
          600: {
            peek: {
              before: 60,
              after: 60
            }
          },
          650: {
            peek: {
              before: 90,
              after: 90
            }
          },
          700: {
            peek: {
              before: 120,
              after: 120
            }
          },
          767: {
            peek: {
              before: 150,
              after: 150
            }
          }
        }
      }
    }
  },
  props: {
    heroes: {
      type: Array,
      default: () => []
    },
    isMobile: {
      type: Boolean,
      default: false
    }
  },
  components: {
    SfHero,
    SfButton,
    SfLink,
    SfOHeroItem
  },
  computed: {
    preparedHeroes () {
      const sortedHeroes = this.heroes.reduce((acc, item) => {
        if (item.position === TOP_BANNER_POSITION_INDEX) {
          acc.push(item);
        }
        return acc;
      }, []).sort((a, b) => a.sort_order > b.sort_order ? 1 : -1);

      return sortedHeroes.map(hero => ({
        ...hero,
        image: {
          mobile: getThumbnailPath(hero.image_mobile),
          desktop: getThumbnailPath(hero.image_desktop)
        }
      }));
    }
  },
  methods: {
    onBannerClick (banner) {
      const payload = [
        {
          item_name: banner.title || 'hero_banner_' + banner.id, // Name or ID is required.
          item_id: banner.id,
          item_category: 'item_category',
          promotion_id: banner.id,
          promotion_name: banner.title || 'hero_banner_' + banner.id,
          creative_name: 'creative_name',
          creative_slot: 'creative_slot',
          location_id: 'hero_banner',
          index: 'index',
          quantity: 1,
          price: 'price'
        }
      ]
      this.gtmBannerHandler(payload, 'select_promotion')
    },
    onBannerShow () {
      const payload = []

      this.preparedHeroes.forEach((banner) => {
        payload.push({
          item_name: banner.title || 'hero_banner_' + banner.id, // Name or ID is required.
          item_id: banner.id,
          price: 'price',
          item_brand: 'item_brand',
          item_category: 'item_category',
          promotion_id: banner.id,
          promotion_name: banner.title || 'hero_banner_' + banner.id,
          creative_name: 'creative_name',
          creative_slot: 'creative_slot',
          location_id: 'hero_banner',
          index: 'index',
          quantity: 1
        })
      })

      this.gtmBannerHandler(payload, 'view_promotion')
    }
  },
  mounted () {
    this.onBannerShow()
  }
};
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.hero {
  ::v-deep {
    .glide__slides {
      @include for-mobile {
        touch-action: pan-x;
      }
    }
  }

  .big-link {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
</style>
