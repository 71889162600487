import { manifestMeta } from './base';
import { mergeMeta } from 'theme/meta/utils/mergeMeta';
import { RobotsEnum } from './types'
import config from 'config';
import i18n from '@vue-storefront/i18n'
import { relRobotsHandler } from 'theme/meta/utils/robots';
import { getThumbnailPath } from '@vue-storefront/core/helpers';

export function metaHome () {
  const i18nStore = config.storeViews?.[this.$store.state.storeView.storeCode]?.i18n
  const currentLocale = i18nStore?.defaultLocale;
  const getHeadImages = this.$store.getters['promoted/getHeadImage'];
  const currentCategory = this.$store.getters['homepage/getMainCategory'];
  const firstHeroBanner = getHeadImages
    .filter((item) => item.position === '0')
    .sort((a, b) => a.sort_order > b.sort_order ? 1 : -1)[0];
  const baseUrl = this.$store.getters['siteData/baseUrl'];
  const url = `${baseUrl}${this.$route.path}`;
  const manifest = manifestMeta();
  const relRobots = relRobotsHandler(RobotsEnum.INDEX, config.xEnv.key);

  const meta = {
    title: currentCategory.meta_title || i18n.t('META_HOMEPAGE_TITLE'),
    meta: {
      ...relRobots,
      title: {
        name: 'title',
        content: currentCategory.meta_title || i18n.t('META_HOMEPAGE_TITLE')
      },
      description: {
        name: 'description',
        content: currentCategory.meta_description || i18n.t('META_HOMEPAGE_DESCRIPTION')
      },
      ogTitle: {
        name: 'og:title',
        content: currentCategory.meta_title || i18n.t('META_HOMEPAGE_TITLE')
      },

      ogDescription: {
        name: 'og:description',
        content: currentCategory.meta_description || i18n.t('META_HOMEPAGE_DESCRIPTION')
      },
      ogType: {
        property: 'og:type',
        content: 'article'
      },
      ogUrl: {
        name: 'og:url',
        content: url
      },
      ogLocale: {
        name: 'og:locale',
        content: currentLocale
      }
    },
    link: [
      // temporarily hidden according to task VAR-3141
      // {
      //   rel: 'canonical',
      //   href: url
      // },
      {
        rel: 'preload',
        href: getThumbnailPath(firstHeroBanner?.image_mobile) || '',
        as: 'image'
      },
      {
        rel: 'preload',
        href: getThumbnailPath(firstHeroBanner?.image_desktop) || '',
        as: 'image'
      },
      ...manifest.link
    ]
  };

  return mergeMeta(meta)
}
