<template>
  <li
    v-if="(buttonShow || titleShow)"
    :style="{ backgroundImage: 'url(' + `${isMobile ? imageMobile : imageDesktop}` + ')', backgroundColor: background }"
    class="glide__slide sf-hero-item"
    :class="{ 'sf-hero-item__with-description': buttonShow || titleShow }"
    data-testid="hero-item"
  >
    <div class="sf-hero-item__wrapper">
      <slot name="title" v-bind="{ title }" v-if="titleShow">
        <span
          :class="{ 'display-none': !title }"
          class="sf-hero-item__title"
        >
          {{ title }}
        </span>
      </slot>
      <slot name="subtitle" v-bind="{ subtitle }" v-if="subTitleShow">
        <span
          :class="{ 'display-none': !subtitle }"
          :style="{ color: subtitleColor }"
          class="sf-hero-item__subtitle"
        >
          {{ subtitle }}
        </span>
      </slot>
      <slot name="call-to-action" v-bind="{ link }" v-if="buttonShow">
        <div class="sf-hero-item__button">
          <SfButton :link="link" data-testid="hero-cta-button">
            {{ $t('More details') }}
          </SfButton>
        </div>
      </slot>
      <slot name="withImgTag" />
      <slot v-if="!titleShow || !buttonShow" name="big-link" v-bind="{ link }" />
    </div>
  </li>
  <li v-else class="glide__slide sf-hero-item sf-hero-item__without-description">
    <picture class="sf-hero-item__picture">
      <source
        :srcset="imageDesktop"
        media="(min-width: 768px)"
      >
      <img
        :src="imageMobile"
        alt="banner-img"
        :loading="isLazy()"
      >
    </picture>
    <slot v-if="!titleShow || !buttonShow" name="big-link" v-bind="{ link }" />
  </li>
</template>
<script>
import SfButton from '@storefront-ui/vue/src/components/atoms/SfButton/SfButton';
import DeviceType from 'theme/mixins/DeviceType';
export default {
  name: 'SfOHeroItem',
  components: {
    SfButton
  },
  mixins: [DeviceType],
  props: {
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    },
    subtitleColor: {
      type: String,
      default: ''
    },
    buttonShow: {
      type: Number,
      default: 0
    },
    titleShow: {
      type: Number,
      default: 0
    },
    subTitleShow: {
      type: Number,
      default: 0
    },
    buttonText: {
      type: String,
      default: ''
    },
    background: {
      type: String,
      default: ''
    },
    imageMobile: {
      type: String,
      default: ''
    },
    imageDesktop: {
      type: String,
      default: ''
    },
    link: {
      type: String,
      default: null
    }
  },
  methods: {
    isLazy () {
      if (this.$vnode.key === 0) {
        return 'eager';
      } else {
        return 'lazy';
      }
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
@import "~theme/css/px2rem";

.sf-hero-item__picture {
  img {
    @media (max-width: $mobile-max){
      width: px2rem(768);
    }
  }
}
</style>
