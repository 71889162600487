<template>
  <SfOImage
    class="m-brands-carousel__image"
    :alt="name"
    :src="urlLogoBrand"
  />
</template>
<script>
import { getBrandImagePath } from 'theme/helpers'
import SfOImage from 'theme/components/storefront-override/SfOImage.vue';

export default {
  name: 'MRenderImage',
  components: {
    SfOImage
  },
  props: {
    brandId: String,
    name: String
  },
  computed: {
    urlLogoBrand () {
      return getBrandImagePath(this.brandId)
    }
  }
}
</script>
