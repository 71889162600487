<template>
  <div id="home" class="home">
    <div class="center">
      <OHomeHero :heroes="heroes" />
      <ShippingWidgetLite
        v-if="isMobile || isTablet"
        class="home__shipping"
      />

      <LazyHydrate when-idle>
        <div class="w-100">
          <MHomeOrder v-if="isLoggedIn" />
        </div>
      </LazyHydrate>
    </div>
    <OHomeFavorites
      v-if="isShowFavorites"
      :loading="loading"
      class="home-favorites o-section--slider"
    />

    <OSection
      :title-heading="$t('Bord')"
      key="bord-products"
      v-if="getBordProducts.length"
      is-centered
      is-not-mobile-padding
      class="o-section--slider home--bord-products"
    >
      <LazyHydrate :trigger-hydration="!loading">
        <div class="w-100">
          <MProductCarousel
            :products="getBordProducts"
            :key="getBordProducts.length"
            arrow-color="grey"
            is-white-card
            :show-qty-permanently="true"
            :lazy="true"
          />
        </div>
      </LazyHydrate>
    </OSection>

    <div :id="productOffersMainWidgetId" />
    <OHomeSpecialOffers
      :loading="loading"
      :offers-main-products="offersMainProducts"
    />

    <OSection
      v-if="getBrandsList.length"
      :title-heading="$t('Brands')"
      is-centered
    >
      <template #link>
        <SfLink
          :link="localizedRoute('/brands')"
          class="sf-link--primary"
        >
          {{ $t('All brands') }}
        </SfLink>
      </template>
      <LazyHydrate :trigger-hydration="!loading">
        <div class="w-100">
          <MBrandsCarouselHomePage />
        </div>
      </LazyHydrate>
    </OSection>

    <div :id="productPopularMainWidgetId" />
    <OSection
      v-if="popularMainProducts.length && popularCategories.length"
      :title-heading="$t('Popular')"
      is-centered
      :background="`#F3F4F6`"
      class="home--popular-products o-section--slider"
    >
      <template #tags>
        <MProductTags
          :tags="popularCategories.slice(0,4)"
          @setActiveCategory="setPopularCategory"
        />
      </template>
      <LazyHydrate :trigger-hydration="!loading">
        <!--      :products=productBestsellers-->
        <div class="w-100">
          <MProductCarousel
            :products="popularProducts"
            :key="popularProducts.length"
            :es-url-param="esUrlParamProductPopularMainWidget"
            arrow-color="white"
            :show-qty-permanently="true"
            :lazy="true"
          />
        </div>
      </LazyHydrate>
    </OSection>
    <MHomeBanner :title="$t('VARUS in pocket')" :description="$t('Download the mobile app')" />
    <template v-if="sampleSpecialCategories.length">
      <OSection
        v-for="specialCategory in sampleSpecialCategories"
        :key="specialCategory.id"
        :title-heading="specialCategory.name"
        is-centered
        :background="`#F3F4F6`"
        class="o-section--slider home--special-categories"
        :class="{'home--special-categories-full' : specialCategory.products.length}"
      >
        <template #link>
          <SfLink
            :link="localizedRoute(specialCategory.url_path)"
            class="sf-link--primary"
          >
            {{ $t('All goods') }}
          </SfLink>
        </template>
        <LazyHydrate :trigger-hydration="!loading">
          <div class="w-100">
            <MProductCarousel
              :products="specialCategory.products"
              :key="specialCategory.products.length"
              arrow-color="white"
              :show-qty-permanently="true"
              :lazy="true"
            />
          </div>
        </LazyHydrate>
      </OSection>
    </template>

    <LazyHydrate :trigger-hydration="!loading">
      <div class="w-100">
        <MRecentlyViewedProducts
          :title-heading="$t('Viewed')"
          :amount="25"
          :show-qty-permanently="true"
        />
      </div>
    </LazyHydrate>

    <div :id="cafeProductsMainWidgetId" />

    <MHomeCafeCarousel
      v-if="cafeProductsMain.length"
      :products="cafeProductsMain"
      :es-url-param="esUrlParamCafeProductsMainWidget"
    />

    <div class="center">
      <MAdvantages />
      <OSection
        v-if="false"
        :title-heading="$t('Cook with VARUS')"
      >
        <template #link>
          <SfLink
            link="/"
            class="sf-link--primary"
          >
            {{ $t('Read recipes') }}
          </SfLink>
        </template>
        <LazyHydrate when-visible>
          <div class="w-100">
            <MRecipeCarousel />
          </div>
        </LazyHydrate>
      </OSection>
      <MSeoBlock v-if="!!getSeoHtml" :seo-html="getSeoHtml" />
    </div>

    <json-ld-home />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import LazyHydrate from 'vue-lazy-hydration';
import { Logger } from '@vue-storefront/core/lib/logger';
import { isServer, onlineHelper } from '@vue-storefront/core/helpers';
import MProductCarousel from '../components/molecules/m-product-carousel';
import MProductTags from 'theme/components/molecules/m-product-tags';
import {
  getAndStoreRelatedProducts,
  getEsputnikUrl,
  getNonProductCategoryIds,
  getRelatedCategories,
  getTopLevelCategories
} from 'theme/helpers'
import OSection from '../components/organisms/o-section';
import { eSputnikEvent } from 'theme/helpers/es';
import { SfLink } from '@storefront-ui/vue';
import MSeoBlock from '../components/molecules/m-seo-block';
import MHomeOrder from '../components/molecules/m-home-order';
import MAdvantages from 'theme/components/molecules/m-advantages';
import MRecipeCarousel from '../components/molecules/m-recipe-carousel';
import OHomeHero from '../components/organisms/o-home-hero';
import MBrandsCarouselHomePage from 'theme/components/molecules/m-brands-carousel-home-page.vue';
import { Wishlist } from 'theme/store/wishlist/components/Wishlist';
import MRecentlyViewedProducts from '../components/molecules/m-recently-viewed-products';
import MHomeBanner from '../components/molecules/m-home-banner';
import MHomeCafeCarousel from '../components/organisms/o-home-cafe-carousel';
import config from 'config';
import JsonLdHome from 'theme/components/json-ld/json-ld-home';
import DeviceType from 'theme/mixins/DeviceType';
import { ModalList } from 'theme/store/ui/modals';
import GoogleTagManager from 'theme/mixins/gtm';
import { metaHome } from '../meta/home';
import OHomeFavorites from 'theme/components/organisms/o-home-favorites';
import OHomeSpecialOffers from 'theme/components/organisms/o-home-special-offers';

export default {
  name: 'Home',
  components: {
    JsonLdHome,
    ShippingWidgetLite: () => import('$modules/shipping/components/shipping-widget-lite'),
    MHomeCafeCarousel,
    MHomeBanner,
    MRecentlyViewedProducts,
    MBrandsCarouselHomePage,
    OHomeHero,
    MProductTags,
    MRecipeCarousel,
    MHomeOrder,
    MAdvantages,
    MSeoBlock,
    OSection,
    LazyHydrate,
    MProductCarousel,
    SfLink,
    OHomeFavorites,
    OHomeSpecialOffers
  },
  mixins: [Wishlist, DeviceType, GoogleTagManager],
  data () {
    return {
      loading: true,
      loadNewsletterPopup: false,
      selectedPopularCategoryIndex: 0,
      nonProductCategoryIds: [],
      isMounted: false,
      esUrlParamProductPopularMainWidget: '',
      esUrlParamCafeProductsMainWidget: ''
    }
  },
  computed: {
    ...mapState({
      needToOpenAuth: state => state.ui.needToOpenAuth,
      heroImages: state => state.promoted.headImage,
      getBrandsList: state => state.brands.brandsList,
      getCategories: state => state.category.list
    }),
    ...mapGetters({
      isLoggedIn: 'user/isLoggedIn',
      productBestsellers: 'homepage/getBestsellers',
      getSampleSpecialCategories: 'homepage/getSampleSpecialCategories',
      getSampleProducts: 'homepage/getSampleProducts',
      getSampleProductsPopular: 'homepage/getSampleProductsPopular',
      getSampleProductsVarusCafe: 'homepage/getSampleProductsVarusCafe',
      getProductRelated: 'product/getProductRelated',
      shippingDetails: 'shipping/getShippingDetails',
      mainCategory: 'homepage/getMainCategory',
      getBordProducts: 'homepage/getBordProducts',
      productsInWishlist: 'wishlist/getWishlistItems'
    }),
    isOnline () {
      return onlineHelper.isOnline;
    },
    heroes () {
      return this.heroImages
    },
    sampleSpecialCategories () {
      return this.getSampleSpecialCategories
    },
    sampleProducts () {
      return this.getSampleProducts
    },
    sampleProductsPopular () {
      return this.getSampleProductsPopular
    },
    getSeoHtml () {
      return this.mainCategory.description
    },
    isShowFavorites () {
      return this.productsInWishlist.length && this.isLoggedIn
    },
    productOffersMainWidgetId () {
      return config.esputnik?.widgetIds?.productOffersMain;
    },
    productPopularMainWidgetId () {
      return config.esputnik?.widgetIds?.productPopularMain;
    },
    cafeProductsMainWidgetId () {
      return config.esputnik?.widgetIds?.cafeProductsMain;
    },
    offersMainProducts () {
      const products = this.getProductRelated['productOffersMain'] || []
      return products.length ? products : this.sampleProducts;
    },
    popularMainProducts () {
      const products = this.getProductRelated['productPopularMain'] || []
      return products.length ? products : this.sampleProductsPopular;
    },
    cafeProductsMain () {
      const products = this.getProductRelated['productCafeMain'] || []
      return products.length ? products : this.getSampleProductsVarusCafe;
    },
    popularCategories () {
      const topLevelCategories = getTopLevelCategories(this.getCategories)
      const productCategories = topLevelCategories.filter(e => !this.nonProductCategoryIds.includes(e.id))
      return getRelatedCategories(this.popularMainProducts, productCategories) || []
    },
    popularProducts () {
      if (!this.popularCategories.length) {
        return []
      }
      const category = this.popularCategories[this.selectedPopularCategoryIndex]
      return category?.products || []
    }
  },
  watch: {
    isLoggedIn () {
      const redirectObj = localStorage.getItem('redirect');
      if (redirectObj) this.$router.push(redirectObj);
      localStorage.removeItem('redirect');
    },
    'shippingDetails.shopId': {
      handler: async function (newValue, oldVal) {
        if (!newValue || newValue === oldVal) return

        await Promise.all([
          this.$store.dispatch('homepage/loadBestsellers'),
          this.$store.dispatch('homepage/loadBordProduct'),
          this.$store.dispatch('homepage/loadSampleSpecialCategories'),
          this.$store.dispatch('homepage/loadSampleProductsPopular', true)
        ])

        this.getProductPopularMain()
        this.getCafeProductsMain()
      }
    }
  },
  async asyncData ({ store, context }) {
    Logger.info('Calling asyncData in Home (theme)')();
    if (context) context.output.cacheTags.add(`home`)

    await Promise.all([
      store.dispatch('homepage/loadMainCategory'),
      store.dispatch('promoted/updateVarusCafeBanners'),
      store.dispatch('homepage/loadBestsellers'),
      store.dispatch('homepage/loadSampleSpecialCategories'),
      store.dispatch('homepage/loadSampleProducts', 'specialOffers'),
      store.dispatch('homepage/loadSampleProductsPopular', true),
      store.dispatch('promoted/updateHeadImage'),
      store.dispatch('homepage/loadAdvantagesContent'),
      store.dispatch('homepage/loadBordProduct'),
      store.dispatch('brands/fetchBrandsList', {}),
      store.dispatch('ui/loadCatalogMenu')
    ]);

    if (context) {
      const banners = (store.state?.promoted?.headImage || [])

      for (const banner of banners) {
        context.output.cacheTags.add(`B${banner.id}`)
      }
    }
  },
  mounted () {
    if (this.needToOpenAuth) {
      this.$store.dispatch('ui/setNeedToOpenAuth', false)
      this.$store.dispatch('ui/openModal', { name: ModalList.Auth, payload: 'auth' })
    }

    if (!this.isLoggedIn && localStorage.getItem('redirect')) { this.$bus.$emit('modal-show', 'modal-signup'); }
    this.getNonProductCategoryIdslocal()
    eSputnikEvent('MainPage')
    window.addEventListener('popularProductsMainLoaded', this.getProductPopularMain)
    window.addEventListener('cafeProductsMainLoaded', this.getCafeProductsMain)

    this.isMounted = true
    this.gtmProductsHandler(this.cafeProductsMain, 'view_item_list')
    this.admitadHomePage()
  },
  beforeDestroy () {
    window.removeEventListener('popularProductsMainLoaded', this.getProductPopularMain)
    window.removeEventListener('cafeProductsMainLoaded', this.getCafeProductsMain)
  },
  beforeRouteEnter (to, from, next) {
    if (!isServer && !from.name) {
      next(vm => {
        vm.loading = false
      });
    } else {
      next();
    }
  },
  methods: {
    ...mapActions({
      openMicrocart: 'ui/toggleMicrocart'
    }),
    getProductPopularMain () {
      getAndStoreRelatedProducts(
        this.productPopularMainWidgetId,
        'productPopularMain',
        config?.entities?.product?.queryMaxSize,
        true
      )
      this.esUrlParamProductPopularMainWidget = getEsputnikUrl('#' + this.productPopularMainWidgetId + ' .recommend-product-id')
    },
    getCafeProductsMain () {
      getAndStoreRelatedProducts(
        this.cafeProductsMainWidgetId,
        'productCafeMain',
        config?.entities?.product?.queryMaxSize,
        true
      )
      this.esUrlParamCafeProductsMainWidget = getEsputnikUrl('#' + this.cafeProductsMainWidgetId + ' .recommend-product-id')
    },
    setPopularCategory (index) {
      this.selectedPopularCategoryIndex = index
    },
    async getNonProductCategoryIdslocal () {
      this.nonProductCategoryIds = await getNonProductCategoryIds()
    }
  },
  metaInfo: metaHome
};
</script>

<style lang="scss" scoped>
@import "~theme/css/breakpoints";
@import "~theme/css/px2rem";

#home {
  box-sizing: border-box;
  padding-top: 10px;
  @include for-desktop {
    padding-top: 20px;
  }

  ::v-deep {
    .home--popular-products {
      @include for-desktop {
        padding: 51px 0 10px;
      }
    }

    .home--special-offers-products {
      background-color: var(--light-gray);
    }

    .home--bord-products {
      @include for-desktop {
        padding: px2rem(51) 0 var(--spacer-10);
      }

      .sf-carousel__controls {
        .sf-arrow {
          &--grey {
            background-color: var(--light-gray);

            &:disabled {
              opacity: .5;
            }

            &:active {
              background-color: var(--orange-pressed);
            }
          }
        }
      }

      .sf-arrow {
        &:hover {
          box-shadow: none;
          background-color: var(--light-gray);
        }
      }
    }

    .m-recently-viewed-products {
      .o-section__content {
        .sf-carousel {
          .glide__slide {
            .sf-product-card {
              max-width: 100%;
              padding-bottom: var(--spacer-20);
            }
          }
        }
      }
    }
  }

  ::v-deep .o-section {
    &--center {
      padding: 0;
    }

    &__heading {
      padding-left: var(--spacer-10);
      @include for-mobile {
        padding-left: var(--spacer-10);
        padding-right: var(--spacer-10);
      }
      @include for-tablet {
        padding-left: var(--spacer-sm);
        padding-right: var(--spacer-sm);
      }
    }

    &__content {
      .sf-carousel {
        .glide__slide {
          flex-shrink: initial;
          padding: 0;

          .sf-product-card {
            max-width: 100%;
            padding-bottom: var(--spacer-20);
          }
        }
      }
      .m-cafe-carousel {
        .glide__slide {
          flex-shrink: 0;
        }
      }
    }
  }

  .o-home-cafe-carousel {
    ::v-deep {
      .o-section__content {
        @include for-mobile {
          padding-left: 0;

          .glide__slide:first-child {
            margin-left: 10px;
          }
        }

        .sf-carousel-item {
          @include for-tablet {
            margin: 0 var(--spacer-10) 0 0;
          }
        }
      }
    }
  }

  ::v-deep .m-recently-viewed-products {
    .o-section--center {
      .o-section__content {
        .sf-carousel__wrapper {
          .glide__slides {
            @include for-desktop {
              margin-bottom: var(--spacer-40);
            }
          }
          .sf-product-card {
            @include for-desktop {
              padding-bottom: 0;
            }
            @include for-tablet {
              max-width: px2rem(190);
              width: px2rem(190);
            }

            .sf-product-card__image {

              img {
                @include for-tablet {
                  max-width: px2rem(170);
                  width: px2rem(170);
                  padding: 0;
                }

                @include for-desktop {
                  padding: 0;
                }
              }
            }
          }
        }

        @include for-tablet {
          padding-left: var(--spacer-10);
        }
      }

      @include for-desktop {
        padding: 0 var(--spacer-5);
      }
    }
  }
}

.home {
  &__shipping {
    @include for-desktop {
      display: none;
    }
  }

  &-favorites {
    padding-top: 0;
  }

  &--popular-products {
    ::v-deep {
      .o-section__heading {
        @include for-mobile {
          display: grid;
          grid-template-areas:
            "a a"
            "b b";
        }
      }
      .sf-heading__title--h3 {
        white-space: nowrap;
        @include for-mobile {
          grid-area: a;
        }
      }
      .m-product-tags {
        @include for-mobile {
          grid-area: b;
          padding-left: var(--spacer-10);
        }
      }
    }
    ::v-deep .o-section {
      &__heading {
        @media (min-width: $desktop-min) {
          padding-bottom: var(--spacer-20);
        }

        .m-product-tags {
          margin-bottom: 0;

          &::-webkit-scrollbar {
            display: none;
          }

          @-moz-document url-prefix() {
            scrollbar-width: none;
          }

          &__link {
            display: flex;
            align-items: center;

            span {
              font-size: var(--font-base);
              line-height: var(--font-size-19);

              @include for-desktop {
                white-space: normal;
              }

              @include for-mobile {
                white-space: nowrap;
              }
            }

            $link: '.m-product-tags__link';

            &:not(#{$link}--active):hover {
              cursor: pointer;
            }

            &:not(#{$link}--active):not(#{$link}--prev) {
              position: relative;

              &:after {
                display: block;
                content: '';
                height: 1.75rem;
                width: 1px;
                background-color: var(--color-zircon);
                position: absolute;
                top: 50%;
                right: 0;
                transform: translateY(-50%);
              }

              &:last-child {
                &:after {
                  display: none;
                }
              }
            }

            &:hover {
              color: initial;
            }
          }
        }
      }
    }
  }

  &--special-categories {
    display: none;
    --section-margin: 0;

    &-full {
      display: block;
    }
  }

  ::v-deep .sf-product-card  {
    .sf-price__regular {
      color: var(--black);

      @media (max-width: $mobile-max) {
        font-size: var(--font-size-16);
      }
    }
  }

  ::v-deep .all-products-link {
    @include for-tablet {
      font-size: var(--font-size-16);
    }
  }
}

::v-deep {
  .home-mobile-address {
    margin-top: var(--spacer-40);
    margin-bottom: var(--spacer-20);

    @media (min-width: $desktop-min) {
      display: none;
    }
  }
}
</style>
